.element-report {
  @extend %element-base;
  @extend %element-base-hover;
  color: white;
  --element-bg: var(--report-background);
  --element-shadow: var(--report-shadow);
  cursor: pointer;
}

.element-report.status-ready {
  @extend %element-base-hover;
}

.element-report.status-success {
  @extend %element-base-hover;
  cursor: pointer;
}

.report-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  margin-bottom: 1rem;
}
