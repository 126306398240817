.model-wizard {
  position: absolute;
  top: 40px;
  z-index: 30000;
  &__body {
    position: relative;
    // width: 1000px;
  }

  &__help-window {
    background-color: white;
    width: 650px;
    max-height: 660px;
    max-width: 650px;
    position: absolute;
    top: 0px;
    right: -5px;
    transform: translateX(100%);
  }
}
