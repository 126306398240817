.element-status {
  @extend %element-base;
  @extend %element-base-hover;
  color: var(--color-text);
  cursor: pointer;
}

.element-status.status-ready {
  @extend %element-base-hover;
}

.element-status.status-success {
  @extend %element-base-hover;
  cursor: pointer;
}

.element-analysis {
  @extend %element-base;
  @extend %element-base-hover;
  color: white;
  --element-bg: var(--accent-background);
  --element-shadow: var(--accent-shadow);
  cursor: pointer;
}

.element-analysis.status-ready {
  @extend %element-base-hover;
}

.element-analysis.status-success {
  @extend %element-base-hover;
  cursor: pointer;
}

.analysis-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  margin-bottom: 1rem;
}
