.canvas-card {
  display: flex;
  // flex-direction: column;
  margin-top: 10px;
  padding: 0.5rem;
  // background-color: white;
  //   border-radius: 0px;
  // outline: 1px solid black;
}

.canvas-chart {
  height: 100%;
  min-height: 205px;
  // border: 1px solid black;
}

.canvas-bar-chart-large {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  // flex-grow: 1;
  height: 90%;
  //   flex-shrink: 1;
  // outline: 1px dashed black;
}

.canvas-bar-chart-small {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  // flex-grow: 1;
  height: 75%;
  //   flex-shrink: 1;
  // outline: 1px dashed black;
}

.bar-chart-item {
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: flex-end;
  flex-direction: column;
  width: 10%;
  max-width: 60px;
  font-weight: bold;
  margin: 0.1rem;
  height: 100%;
  font-size: 0.45rem;
}

.bar-chart-item:hover {
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: flex-end;
  flex-direction: column;
  // width: 10%;
  // max-width: 60px;
  margin: 0.1rem;
  height: 100%;
  font-size: 0.45rem;
  // outline: 1px solid black;
}

.axis-bar-chart-small {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 10%;
  border-top: 1px solid grey;
  // outline: 1px dashed blue;
}

.axis-bar-chart-large {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 25%;
  border-top: 1px solid grey;
  // outline: 1px dashed blue;
}

.axis-bar-chart-item-large {
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  font-size: calc(80%);
  font-weight: bold;
  width: 10%;
  height: 100%;
  min-width: 0;
  max-width: 60px;
  flex: 1;
  margin: 0.1rem;
  margin-top: 0;
  margin-bottom: 0;
  // font-size: 0.45rem;
  // outline: 1px solid red;
  // transform: rotate(20deg);
  // white-space: wrap;
}
.axis-bar-chart-item-large:hover {
  opacity: 0.2;
  // outline: 1px solid green;
}

.axis-bar-chart-item-small {
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  // font-size: calc(95%);
  width: 10%;
  height: 100%;
  min-width: 0;
  max-width: 60px;
  flex: 1;
  margin: 0.1rem;
  margin-top: 0;
  margin-bottom: 0;
  // font-size: 0.45rem;
  // outline: 1px solid red;
  // white-space: wrap;
}
.axis-bar-chart-item-small:hover {
  opacity: 0.2;
  // outline: 1px solid green;
}

.bar-chart-item-label {
  font-size: 4rem;
  padding: 0.2rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
