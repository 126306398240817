// Annotation card

.list-group-inline
.element-annotation {
  background-color: var(--training-bg);
  border: 0 !important;
  border-radius: $border-radius-sm !important;
}
.element-annotation {
  display: flex;
  align-items: center;
  gap: 1rem;
}
