.block-generator {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0.5rem;
  // border: 1px solid black;
  gap: 0.5rem;
  &__chart1 {
    // outline: 1px solid #f00;
    grid-row: 1/5;
  }
  &__chart2 {
    // outline: 1px solid #0f0;
    grid-row: 1/5;
  }
  &__global-complement {
    // outline: 1px solid #00f;
    grid-row: 5;
    grid-column: 1 / 3;
  }
}

.block-generator-vertical {
  display: grid;
  padding: 0.5rem;
  grid-template-columns: "1fr";
  width: "100%";
  // border: 1px solid rgb(161, 10, 138);
  gap: 0.5rem;
  &__chart1 {
    // outline: 1px solid rgb(164, 154, 14);
    width: "10%";
    grid-row: 1/2;
  }
  &__chart2 {
    // outline: 1px solid #0f0;
    width: "100%";
    grid-row: 2/3;
  }
  &__global-complement {
    outline: 1px solid #00f;
    width: "100%";
    grid-row: 3/4;
  }
}

.block-generator-1x12 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 0.5rem;
  // border: 1px solid black;
  gap: 0.5rem;
  &__chart1 {
    // outline: 1px solid #f00;
    grid-row: 1/5;
  }
  &__global-complement {
    // outline: 1px solid #00f;
    grid-row: 5;
    grid-column: 1 / 3;
  }
}

.block-generator-c1 {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 0.5rem;
  // outline: 1px solid #f00;
  height: 100%;
  &__chart1 {
    // outline: 1px solid #f00;
    flex-grow: 1;
  }
  // &__global-complement {
  //   outline: 1px solid #00f;
  // }
}
