//
// GLOBAL
// - Grid sizes
// - Theme colors
// - Components vars
// - Tiles sizes
//

// Estas variables son de bootstrap.
// Es necesario redefinirlas para usarlas
// ya que bootstrap carga despues.
$white: #ffffff;
$black: #000014;
$bg: #ecf4f4; // Default was #ebedef

$color-default: $black;
$color-accent: #0da8ff;
$color-deeper: #d131eb;
$color-ondemand: #f3dc11;
$color-light-success: #b6ebd1;
$color-success: #52e099;
$color-light-danger: #e2a4b9;
$color-danger: #e05281;
$color-kb: #b87333;

$color-neutral: #bdd6db;
$color-negative: #e05281;
$color-positive: #52e099;

$color-uploading: $color-accent;
$color-training: #f6e445;
$color-ready: $color-positive;

$color-stable: $color-positive;
$color-modified: #d6d627;
$color-deleted: $color-negative;
$color-created: $color-accent;

$color-controls: #0a85c2;

// Grid

$grid-margin: 1.5rem; // 24px [CUSTOM]
$grid-margin-lg: 2.5rem; // 24px [CUSTOM]
$grid-gutter-width: 1rem; // 16px

// Colors

$theme-colors: (
  "default": $color-default,
  "accent": $color-accent,
  "deeper": $color-deeper,
  "kb": $color-kb,
  "on-demand": $color-ondemand,
  "uploading": $color-uploading,
  "training": $color-training,
  "ready": $color-ready,
  "success": $color-success,
  "neutral": $color-neutral,
  "negative": $color-negative,
  "positive": $color-positive,
  "brand": $black,
  "black": $black,
  "modified": $color-modified,
  "deleted": $color-deleted,
  "created": $color-created,
  "stable": $color-stable,
);

// Components
// $control-border-color:      transparent;
// $control-bg:                rgba($controls-color,.05);
// $control-hover-bg:          rgba($controls-color,.08);
// $control-focus-bg:          $white;

// Tiles

$tile-sizes: (
  "1x1": (
    1,
    1,
  ),
  "1x2": (
    1,
    2,
  ),
  "1x3": (
    1,
    3,
  ),
  "1x4": (
    1,
    4,
  ),
  "2x1": (
    2,
    1,
  ),
  "2x2": (
    2,
    2,
  ),
  "2x3": (
    2,
    3,
  ),
  "2x4": (
    2,
    4,
  ),
  "3x1": (
    3,
    1,
  ),
  "3x2": (
    3,
    2,
  ),
  "3x3": (
    3,
    3,
  ),
  "3x4": (
    3,
    4,
  ),
  "4x1": (
    4,
    1,
  ),
  "4x2": (
    4,
    2,
  ),
  "4x3": (
    4,
    3,
  ),
  "4x4": (
    4,
    4,
  ),
  "5x1": (
    5,
    1,
  ),
  "5x2": (
    5,
    2,
  ),
  "5x3": (
    5,
    3,
  ),
  "5x4": (
    5,
    4,
  ),
  "6x1": (
    6,
    1,
  ),
  "6x2": (
    6,
    2,
  ),
  "6x3": (
    6,
    3,
  ),
  "6x4": (
    6,
    4,
  ),
  "7x1": (
    7,
    1,
  ),
  "7x2": (
    7,
    2,
  ),
  "7x3": (
    7,
    3,
  ),
  "7x4": (
    7,
    4,
  ),
  "8x1": (
    8,
    1,
  ),
  "8x2": (
    8,
    2,
  ),
  "8x3": (
    8,
    3,
  ),
  "8x4": (
    8,
    4,
  ),
  "9x1": (
    9,
    1,
  ),
  "9x2": (
    9,
    2,
  ),
  "9x3": (
    9,
    3,
  ),
  "9x4": (
    9,
    4,
  ),
  "10x1": (
    10,
    1,
  ),
  "10x2": (
    10,
    2,
  ),
  "10x3": (
    10,
    3,
  ),
  "10x4": (
    10,
    4,
  ),
  "11x1": (
    11,
    1,
  ),
  "11x2": (
    11,
    2,
  ),
  "11x3": (
    11,
    3,
  ),
  "11x4": (
    11,
    4,
  ),
  "12x1": (
    12,
    1,
  ),
  "12x2": (
    12,
    2,
  ),
  "12x3": (
    12,
    3,
  ),
  "12x4": (
    12,
    4,
  ),
);
