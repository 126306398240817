.subscription-enabled {
  border-color: black;
  border-style: solid;
  border-width: 2px;
  height: 28rem;
  margin: 0.1rem;
  margin-bottom: 0.2rem;
}
.subscription-disabled {
  @extend %element-block;
  @extend %element-block-hover;
  text-align: center;
  height: 30rem;
  margin: 0.1rem;
  margin-bottom: 0.2rem;
  border: 1px solid black;
}

.appsumo-tier-enabled {
  border-color: #1b9e3e;
  border-style: double;
  border-width: 0.4rem;
  outline: "1px solid #1b9e3e";
  height: 16rem;
}
.appsumo-tier-disabled {
  border-color: secondary;
  height: 16rem;
}
