.mark-segment {
  --mark-tint:  var(--rgb-accent);
  --mark-color: rgb(var(--mark-tint));
  background: rgba(var(--mark-tint),.2);
  padding: .25rem;
  padding-right: .125rem;
  border-radius: .25rem;
}

.mark-tag {
  background: var(--mark-color);
  padding: .125rem .375rem;
  border-radius: .5rem;
  color: $white;
  margin-left: .5rem;
  font-size: 0.75rem;
  line-height: 1;
  white-space: nowrap;
}

.mark-positive { --mark-tint:  var(--rgb-positive); }
.mark-negative { --mark-tint:  var(--rgb-negative); }
