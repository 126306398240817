// Base styles for custom cards
%element-base {
  --element-color: var(--color-inverse);
  --element-bg: var(--color-surface);
  --element-shadow: var(--shadow-sm);
  background: var(--element-bg);
  box-shadow: var(--element-shadow);
  color: var(--element-color);
  margin-bottom: 0;
}

%element-base-hover {
  & {
    will-change: box-shadow;
    transition: box-shadow var(--transition-default);
  }
  &:hover {
    --element-shadow: var(--shadow);
  }
}

// Base styles for default block

// !importants are for override
// components with complex selectors
%element-control {
  background: var(--control-bg) !important;
  backdrop-filter: var(--background-blur);
  transition: background var(--transition-default);

  &:hover,
  &:focus {
    background: var(--control-hover-bg) !important;
    text-decoration: none;
  }
  &:focus {
    background: var(--control-hover-bg) !important;
    text-decoration: none;
  }
}

// Default control block
//
// 1. CWidgetIcon define usa la clase rounded-xs
//    y no hay otra forma de que funcione aun.

%element-block {
  background: var(--block-bg);
  backdrop-filter: var(--background-blur);
  transition: background var(--transition-default);
  border-radius: $border-radius !important; // 1
}

%element-block-hover:hover {
  background: var(--block-hover-bg);
  text-decoration: none;
}

%element-dropdown {
  background: var(--block-bg);
  backdrop-filter: var(--background-blur);
  transition: background var(--transition-default);
  border-radius: $border-radius !important; // 1
}

%element-dropdown-hover:hover {
  background: var(--block-hover-bg);
  text-decoration: none;
}

%element-button {
  background: var(--block-bg);
  backdrop-filter: var(--background-blur);
  transition: background var(--transition-default);
  border-radius: 0;
}

%element-button-hover:hover {
  background: var(--block-hover-bg);
  text-decoration: none;
}
