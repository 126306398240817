.classification-badge-positive {
  @extend %badge-base-positive;
  @extend %badge-base-positive-hover;
  border-color: grey;
  display: flex;
  // padding: ".2rem";
}

.classification-badge-negative {
  @extend %badge-base-negative;
  @extend %badge-base-negative-hover;
  border-color: grey;
  display: flex;
  // padding: ".2rem";
}

.classification-badge-neutral {
  @extend %badge-base-neutral;
  @extend %badge-base-neutral-hover;
  border-color: grey;
  display: flex;
  // padding: ".2rem";
}

.classification-badge {
  @extend %badge-base-neutral;
  @extend %badge-base-neutral-hover;
  border-color: grey;
  display: flex;
  // padding: ".2rem";
}

.sentiment-badge {
  @extend %badge-base-neutral;
  @extend %badge-base-neutral-hover;
  display: flex;
  flex-direction: row;
  border-color: grey;
  // padding: ".2rem";
}

%badge-base {
  @extend %badge-base-deep;
  background: var(--block-bg);
}

%badge-base-hover:hover {
  @extend %badge-base-deep;
  background: var(--block-hover-bg);
}

%badge-base-negative {
  @extend %badge-base-deep;
  background: var(--block-negative);
}

%badge-base-positive {
  @extend %badge-base-deep;
  background: var(--block-positive);
}

%badge-base-neutral {
  @extend %badge-base-deep;
  background: var(--block-neutral);
}

%badge-base-negative-hover:hover {
  @extend %badge-base-hover-deep;
  background: var(--block-hover-negative);
}

%badge-base-positive-hover:hover {
  @extend %badge-base-hover-deep;
  background: var(--block-hover-positive);
}

%badge-base-neutral-hover:hover {
  @extend %badge-base-hover-deep;
  background: var(--block-hover-neutral);
}

%badge-base-deep {
  align-items: flex-start;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-right: ".3rem";
  border-style: solid;
  border-width: 1px;
  backdrop-filter: var(--background-blur);
  transition: background var(--transition-default);
}

%badge-base-hover-deep:hover {
  text-decoration: none;
}
