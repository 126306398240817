.icon {
  --icon-size: inherit;
  display: inline-block;
  color: inherit;
  text-align: center;
  fill: currentColor;
  width: 1em;
  height: 1em;
  font-size: var(--icon-size);
}

.emoji {
  --icon-size: inherit;
  display: inline-block;
  font-size: var(--icon-size);
}

.icon-xs   { --icon-size: var(--icon-size-xs) !important; }
.icon-sm   { --icon-size: var(--icon-size-sm) !important; }
.icon-md   { --icon-size: var(--icon-size-md) !important; }
.icon-lg   { --icon-size: var(--icon-size-lg) !important; }
.icon-xl   { --icon-size: var(--icon-size-xl) !important; }
.icon-xxl  { --icon-size: var(--icon-size-xxl) !important; }
.icon-xxxl { --icon-size: var(--icon-size-xxxl) !important; }
