.element-deeper {
  @extend %element-base;
  --element-bg: var(--deeper-background);
  --element-shadow: var(--deeper-shadow);
}

.element-ondemand {
  @extend %element-base;
  --element-bg: var(--ondemand-background);
  --element-shadow: var(--ondemand-shadow);
}

.element-deeper,
.element-ondemand {
  .card-header {
    padding-bottom: .5rem;
  }
}
