.activity-indicator {
  position: relative;
}

.activity-spinner {
  width: 3rem;
  height: 3rem;
}

.activity-icon {
  & {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-size: 28px;
    line-height: 1;
  }
  .icon {
    vertical-align: top;
  }
}

.activity-stop1 {
  stop-color: rgba(#FFF,.01);
  stop-opacity: 0;
}
.activity-default   { stop-color: $color-default; }
.activity-uploading { stop-color: $color-uploading; }
.activity-training  { stop-color: $color-training; }
.activity-ready     { stop-color: $color-ready; }



.activity-progress {
  & {
    display: flex;
    align-items: center;
    gap: .5rem;
  }

  .progress {
    width: 3.5rem;
    height: .5rem;
  }
}

