.element-shared {
  @extend %element-base;
  --element-bg: var(--accent-background);
  --element-shadow: var(--accent-shadow);
  position: fixed;
  z-index: 1000;
  right: 1rem;
  bottom: 1rem;
  max-width: 260px;
}

.element-shared-logo {
  margin-top: 0.75em;
  margin-right: -0.6em;
}
