.model-help {
  &__close-button {
    float: right;
    font-size: 1.3125rem;
    font-weight: 700;
    line-height: 1;
    opacity: .5;
    color: #000015;
    background-color: transparent;
    border: 0;
    text-shadow: 0 1px 0 #fff;
    margin: -1rem -1rem -1rem auto;
    padding: 1rem 1rem;
  }
}
