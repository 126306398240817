//
// UTILITIE CLASSES
// - Shapes
// - Flex and gaps
// - Centering
//

.text-underline {
  text-decoration: underline;
}

.text-neutral  { color: var(--color-neutral); }
.text-positive { color: var(--color-positive); }
.text-negative { color: var(--color-negative); }

// Shapes

.rounded-xs {
    border-radius: $border-radius-xs;
}

.list-group-inline {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

// Flex and gaps
.flex {
  display: flex;
  flex-direction: column;
}

.flex-line {
  display: flex;
  align-items: center
}

.flex-horizontal {
  display: flex !important;
  align-items: flex-start;
}
.flex-vertical {
  display: flex !important;
  flex-direction: column !important;
}

.flex-vertical,
.table-responsive {
  flex: 1;
}

.gap-sm { gap: .5rem; }
.gap-md { gap: 1rem; }
.gap-lg { gap: 1.5rem; }

// Centering

.flex-center {
    margin: auto;
}
.flex-center-h {
    margin-left: auto;
    margin-right: auto;
}
.flex-center-v {
    margin-top: auto;
    margin-bottom: auto;
}
