.btn-control {
  @extend %element-control;
}

.btn-gradient {
  & {
    border: none !important;
    border-color: transparent !important;
    background-size: 110% 110%;
    font-weight: 600;
  }
  &.btn-default {
    background: var(--default-background);
    box-shadow: var(--default-shadow)
  }
  &.btn-accent {
    background: var(--accent-background);
    box-shadow: var(--accent-shadow)
  }
  &.btn-deeper {
    background: var(--deeper-background);
    box-shadow: var(--deeper-shadow)
  }
  &.btn-ondemand {
    background: var(--ondemand-background);
    box-shadow: var(--ondemand-shadow)
  }
}


.btn-clear {
  --button-color: currentColor;

  & {
    position: relative;
    overflow: hidden;
  }
  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background: var(--button-color);
    opacity: 0;
  }
  &, &:hover {
    color: var(--button-color);
  }
  &:hover {
    &:before {
      opacity: .1;
    }
  }
}

// Button circle for add button

.btn-circle {
  & {
    border-radius: $border-radius-xlg;
    line-height: 1;
    padding: .5rem;
  }
  .c-icon,
  .icon {
    margin: 0;
  }
}

//.btn-lg.btn-circle,
//.btn-group-lg > .btn.btn-circle {
//    padding: .75rem;
//    //padding: 0.5rem 0.75rem;
//}

//.btn-lg.btn-circle {
//  .c-icon {
//    width: 20px !important;
//    height: 20px !important;
//    //margin-bottom: 4px;
//  }
//}
