.element-kb {
  @extend %element-base;
  @extend %element-base-hover;
  --element-bg: var(--kb-background);
  --element-shadow: var(--kb-shadow);
  cursor: pointer;
}

.kb-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  margin-bottom: 1rem;
}
