.element-action {
  @extend %element-block;
  @extend %element-block-hover;
  margin-bottom: 0;
  background: transparent;
  border: 2px dashed;
  border-color: var(--color-border);
  width: 100%;
  cursor: pointer;
}

.element-action-inactive {
  @extend %element-block;
  margin-bottom: 0;
  background: transparent;
  border: 2px dashed;
  border-color: var(--color-border);
  width: 100%;
  cursor: not-allowed;
}

.element-tab-selection {
  @extend %element-block;
  @extend %element-block-hover;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: transparent;
  // border: 1px dashed;
  // border-color: var(--color-border);
  width: 100%;
  padding: 1rem;
  gap: 0.5rem;
  margin: 2px !important;
  outline: 1px dashed var(--color-border);
  cursor: pointer;
  &__selected {
    @extend %element-block;
    @extend %element-control;
    @extend %element-block-hover;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: transparent;
    // border: 1px dashed;
    // border-color: var(--color-border);
    width: 100%;
    padding: 1rem;
    gap: 0.5rem;
    margin: 2px !important;
    outline: 1px solid var(--color-accent);
    cursor: pointer;
  }
}
