.element-shortcut {
  @extend %element-base;
  & {
    --element-bg: var(--action-background);
    --element-shadow: var(--action-shadow);
    justify-content: space-between;
    align-self: stretch;
    flex: 1;
  }
  .card-body {
    flex: none;
  }
  .icon {
    font-size: var(--icon-size);
  }
}
