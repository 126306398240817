.canvas-delta-indicator {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.delta-indicator-start {
  display: flex;
  justify-content: flex-start;
  padding-left: 1rem;
  // padding-bottom: 0.3rem;
  font-size: 1rem;
  width: 50%;
}

.delta-indicator-end {
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
  // padding-bottom: 0.3rem;
  font-size: 1rem;
  width: 50%;
}

.delta-indicator-value {
  font-weight: 700;
}

.delta-indicator-text {
  padding-left: 0.2rem;
  color: "grey";
}
