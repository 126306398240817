//
// OVERRIDES
//
// COMPONENTS
// - Cards
// - Dropdowns
// - Tables
// - Modals
//
// ELEMENTS
// - Navbar icon buttons
// - Segmented progress bar
// - Button circle for add button
//

// Cards

.card {
}
.card-title {
  margin-bottom: 0;
}
.card-header {
  background: transparent;
}
.card-header + .card-body {
  padding-top: 0;
}
.card-body-pull {
  margin-left: -$card-spacer-x;
  margin-right: -$card-spacer-x;
}

.card-sm {
  .card-header {
    padding-bottom: .5rem;
    //padding-bottom: .5rem;
    display: flex;
    align-items: center;
    gap: .5rem;
  }
  .card-title {
    font-size: 1.1rem;
  }
}

// Dropdowns

.dropdown-menu {
  box-shadow: $box-shadow-sm;
}

// Tables

.table {
  & {
    border-top: 1px solid $border-color;
  }
  th:first-child,
  td:first-child {
    padding-left: 1.5rem;
  }
  th:last-child,
  td:last-child {
    padding-right: 1.5rem;
  }
  thead th {
    border-top: 0;
    border-bottom: 0;
    font-size: 0.8rem;
  }
  thead tr + tr th {
    padding-top: 0rem;
    padding-bottom: 1rem;
  }
}

// Modals

.modal-danger .modal-content {
  background: #e55353;
  color: white;
}

.modal-header ~ .modal-body,
.modal-footer {
  padding-top: 0 !important;
}

.modal-footer {
  padding: $modal-inner-padding - $modal-footer-margin-between / 2;
  padding: $modal-inner-padding;
}

.modal-footer > * {
  margin: 0;
}

// ELEMENTS

// Navbar icon buttons

.c-header-nav-link {
  & {
    transition: background 200ms;
    border-radius: 2rem;
    padding: 0.25rem !important;
    font-size: 1.4rem;
  }
  &:hover {
    background: rgba(black, 0.1);
  }
}

// Segmented progress bar

.progress-lines {
  & {
    height: 0.75rem;
    background: transparent;
    gap: 2px;
  }
  .progress-bar {
    border-radius: 1em;
  }
}

.list-group-flush {
  .list-group-item {
    border-bottom: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}



