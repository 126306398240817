.element-project {
  @extend %element-base;
  @extend %element-base-hover;
  --element-color: var(--color-text);

  & {
    margin-bottom: 1rem;
  }

  // -Fix dropdown position on card
  .dropdown {
    margin-right: -.5rem;
  }
}
