.tooltip-top {
  outline: 1px solid black;
  font-size: 0.65rem;
  font-weight: normal;
  border-radius: 5px;
  width: 160px;
  overflow: hidden;
  position: relative;
  top: 5px;
  right: 60px;
  background-color: #fff;
  z-index: 10000000;
  // border: 1px purple solid;
}

.tooltip-fixed {
  outline: 1px solid black;
  border-radius: 5px;
  width: 160px;
  overflow: hidden;
  position: absolute;
  top: 5px;
  right: 60px;
  background-color: #fff;
  z-index: 10000000;
  text-align: center;
}

.tooltip-distribution-fixed {
  outline: 1px solid black;
  border-radius: 5px;
  width: 160px;
  overflow: hidden;
  position: absolute;
  top: 50px;
  right: 60px;
  background-color: #fff;
  z-index: 10000000;
  text-align: center;
}
