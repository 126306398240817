@each $size, $values in $tile-sizes {
  .tile-#{$size} {
    @include debug($values);
    --tile-width:  #{nth($values, 1)};
    --tile-height: #{nth($values, 2)};
  }
}

.tile {
  padding: 1rem 1.25rem;
}

.tile-title {}
.tile-actions {}
.tile-header {}
.tile-block {}
.tile-line {
  @extend .flex-line;
  justify-content: space-between;
}

.tile-footer {}
