//
// VARIABLES
// - Common
// - Grid and layout
// - Backgrounds
//
// PLACEHOLDERS
// - Flex header
// - Flex content
//

@function rgb-val($_color) {
  $_red: red($_color);
  $_green: green($_color);
  $_blue: blue($_color);
  @return "#{$_red}, #{$_green}, #{$_blue}";
}

// Common

:root {
  --background-blur: blur(16px);
  --transition-default: 200ms;
}

// Grid and layout

:root {
  & {
    --grid-margin: #{$grid-margin};
    --grid-margin-pull: #{-$grid-margin};
    --header-spacing-top: 1rem;
    --header-spacing-bottom: 1rem;
    --footer-spacing-top: 1rem;
    --footer-spacing-bottom: 2rem;
  }
  @include media-breakpoint-up(md) {
    --grid-margin: #{$grid-margin-lg};
    --grid-margin-pull: #{-$grid-margin-lg};
    --header-top-spacing: 1.25rem;
    --header-bottom-spacing: 1.25rem;
  }
  @include media-breakpoint-up(lg) {
    --header-top-spacing: 2.25rem;
    --header-bottom-spacing: 1.25rem;
  }

  // --icon-xs-size:   1rem; // 16px
  // --icon-sm-size:   1rem; // 16px

  --icon-size-xs: 0.875rem; // 14px
  --icon-size-sm: 1rem; // 16px
  --icon-size-md: 1.25rem; // 20px
  --icon-size-lg: 1.5rem; // 24px
  --icon-size-xl: 1.75rem; // 28px
  --icon-size-xxl: 2rem; // 32px
  --icon-size-xxxl: 2.5rem; // 40px
}

// Backgrounds

:root {
  --color-text: #{$black};
  --color-border: #{rgba($black, 0.2)};
  --color-inverse: #{$white};
  --color-surface: #{$white};

  --color-brand: #{$color-default};
  --color-default: #{$color-default};
  --color-accent: #{$color-accent};
  --color-neutral: #{$color-neutral};
  --color-success: #{$color-success};

  --color-deeper: #{$color-deeper};
  --color-kb: #{$color-kb};
  --color-ondemand: #{$color-ondemand};
  --color-share: var(--color-success);

  --color-uploading: #{$color-uploading};
  --color-training: #{$color-training};
  --color-ready: #{$color-ready};

  --color-neutral: #{$color-neutral};
  --color-positive: #{$color-positive};
  --color-negative: #{$color-negative};

  --rgb-accent: #{rgb-val($color-accent)};
  --rgb-neutral: #{rgb-val($color-neutral)};
  --rgb-positive: #{rgb-val($color-positive)};
  --rgb-negative: #{rgb-val($color-negative)};

  --training-bg: #{rgba($color-training, 0.17)};

  --block-negative: #{rgba($color-negative, 0.25)};
  --block-hover-negative: #{rgba($color-negative, 0.3)};

  --block-neutral: #{rgba($color-neutral, 0.05)};
  --block-hover-neutral: #{rgba($color-neutral, 0.12)};

  --block-positive: #{rgba($color-positive, 0.25)};
  --block-hover-positive: #{rgba($color-positive, 0.3)};

  --block-bg: #{rgba($color-controls, 0.05)};
  --block-hover-bg: #{rgba($color-controls, 0.12)};

  --control-bg: #{rgba($color-controls, 0.05)};
  --control-hover-bg: #{rgba($color-controls, 0.12)};
  --control-focus-bg: #{$white};

  --default-background: linear-gradient(93.71deg, #004466 0%, #000000 100%);
  --dataset-background: linear-gradient(107.82deg, #8f92a3 0%, #5c5f70 100%);
  --category-background: linear-gradient(107.82deg, #d131eb 0%, #9931eb 100%);
  --accent-background: linear-gradient(93.71deg, #4fcaff 0%, #0da8ff 100%);
  --report-background: linear-gradient(93.71deg, #089762 0%, #055f3b 100%);
  --deeper-background: linear-gradient(107.82deg, #d131eb 0%, #9931eb 100%);
  --kb-background: linear-gradient(107.82deg, #af8a67 0%, #b87333 100%);
  --ondemand-background: linear-gradient(107.82deg, #f3dc11 0%, #cbab0b 100%);
  --help-background: linear-gradient(180deg, #556d91 0%, #130207 100%);
  --action-background: rgba(0, 0, 0, 0.8);

  --shadow: #{$box-shadow};
  --shadow-sm: #{$box-shadow-sm};
  --shadow-lg: #{$box-shadow-lg};

  --default-shadow: 0px 6px 32px rgba(34, 180, 243, 0.1),
    0px 4px 8px rgba(34, 180, 243, 0.4);
  --dataset-shadow: 0px 6px 32px rgba(143, 146, 163, 0.05),
    0px 4px 8px rgba(143, 146, 163, 0.4);
  --accent-shadow: 0px 6px 32px rgba(34, 180, 243, 0.1),
    0px 4px 8px rgba(34, 180, 243, 0.4);
  --report-shadow: 0px 6px 32px rgba(2, 40, 25, 0.928),
    0px 4px 8px rgba(34, 180, 243, 0.4);
  --deeper-shadow: 0px 6px 32px rgba(209, 49, 235, 0.1),
    0px 4px 8px rgba(209, 49, 235, 0.4);
  --kb-shadow: 0px 6px 32px rgba(183, 115, 51, 0.1),
    0px 4px 8px rgba(183, 115, 51, 0.4);
  --ondemand-shadow: 0px 6px 32px rgba(243, 220, 17, 0.1),
    0px 4px 8px rgba(243, 220, 17, 0.4);
  --action-shadow: 0px 6px 32px rgba(0, 0, 0, 0.05),
    0px 2px 6px rgba(0, 0, 0, 0.1);

  --app-background: radial-gradient(
    50.05% 70.52% at 86.75% 0%,
    #d4fbf3 8.85%,
    #d5f1fd 39.58%,
    #f3fbfe 74.48%,
    #ecf4f4 100%
  );
}

// PLACEHOLDERS

%flex-horizontal {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  flex-shrink: 0 !important;
}

%flex-vertical {
  display: flex !important;
  flex-direction: column !important;
  min-height: 100% !important;
  flex: 1 !important;
}
