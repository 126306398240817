.dashboard-component-card {
  background-color: white;
  margin-bottom: 1rem;
  margin-left: 0.2rem;
  margin-right: 0;
  padding: 0;
  //   border-color: $secondary-100;
  //   border-style: solid;
  //   border-width: 0.1rem;
  //   box-shadow: 0ch;
  box-shadow: none;
  outline: 1px solid red;
}

.dashboard-component-header {
  //   background-color: $secondary-200;
  margin: 0;
  padding: 0;
  min-height: 2.4rem;
  outline: 1px solid red;
  //   border-color: $secondary;
  //   border-style: solid;
  //   border-bottom-color: $dark;
  //   border-bottom-width: 0.1rem;
}

.dashboard-component-title {
  margin: 0.7rem;
  font-size: 1rem;
  color: black;
  font-weight: bolder;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  outline: 1px solid red;
}

.dashboard-component-help-button {
  margin: 0.05rem;
  border-radius: 3px;
  border-color: $dark;
}
