//
// FIXES
//
// - Disabled button styles
// - Font-size based icon
// - Close tooltip when mouse leave
//
// - Select on filter dropdowns
// - Brand button icon margin
// - Sideba minimizer overflow
// - Modal size in relative containers
//


.c-icon {
  flex-shrink: 0;
}

// todo: disabled button styles
button {
  &:disabled {
    cursor: default;
  }
  &.disabled {
    cursor: default;
  }
}

$secondary-color: #636f83;

//.btn-outline-dark {
//  border-color: rgba(0, 0, 20, 0.2) !important;
//}

.btn-outline-light {
//  border-color: rgba(0, 0, 20, 0.2) !important;
  color: #636f83 !important;
}

.danger-on-hover:hover {
  background-color: $danger !important;

  &, svg {
    color: $white !important;
  }
}


// Font-size based icon
// .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
//   width: 1em !important;
//   height: 1em !important;
//   font-size: inherit !important;
//   //margin: 0 0 0.04em 0 !important;
//   //color: inherit !important;
//   //fill: inherit !important;
// }

// Close tooltip when mouse leave
.react-tooltip-lite {
  pointer-events: none;
  user-select: none;
}

// Select on filter dropdowns
.basic-select {
  margin: 0 !important;
}
.basic-dropdown .dropdown-menu {
  padding: 0;
  top: 8px !important;
}

// todo: brand button icon margin
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
  }
}

// Sideba minimizer overflow
// Prevent element overflow the parent container
.c-sidebar-minimizer {
  width: auto;
}
.c-sidebar-brand {
  justify-content: flex-start;
  padding-left: 0.5rem;
  margin: 0.25rem 0;
}
.c-sidebar-nav-link {
  font-size: 1.1rem;
}
.c-sidebar-nav-icon {
  height: 1.25rem;
}

// Fix modal size in relative containers
.modal {
  height: 100vh;
}


// BLAME
// Fix this the otra manera :D

.btn-pill {
  .c-icon {
    margin: 0;
  }
}

.pagination {
  & {
    margin-bottom: 0;
  }
  .card-body-pull & {
    margin-left: 1rem;
  }
}

.card-tile {
  .card-footer {
    padding-top: 0;
  }
}
