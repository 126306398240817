.filterbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  position: sticky;
  top: 80px;

  z-index: 300;

  padding: 0;
  margin-top: 0.25rem;
  // margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  opacity: 0.95;
  // outline: green solid 1px;
}

// .filterbar :nth-last-child(1) {
//   margin-left: auto;
// }

.search-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: top;
  border-radius: 16px;
  // outline: black solid 2px;
  margin-left: auto;
}

.searchbar {
  display: flex;
  align-self: start;
  align-items: center;
  border-radius: 16px;
  background-color: rgba(10, 133, 194, 0.15);
  outline: black solid 1px;
  margin-left: auto;
}

.filterbar-dropdown {
  margin-right: 0.1rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0;
}

.filterbar-button {
  color: black;
}
