.element-dataset {
  @extend %element-base;
  --element-bg: var(--dataset-background);
  --element-shadow: var(--dataset-shadow);
}

.element-add-dataset {
  @extend %element-base;
  --element-bg: var(--white);
  --element-shadow: var(--dataset-shadow);
  border-style: dashed;
  border-color: black;
  border-width: 1px;
  cursor: pointer;
  color: black;
}
