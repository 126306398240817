.element-ai-module {
  @extend %element-base;
  @extend %element-base-hover;
  color: white;
  --element-bg: var(--deeper-background);
  --element-shadow: var(--deeper-shadow);
  cursor: pointer;
}

.element-ai-module.status-ready {
  @extend %element-base-hover;
}

.element-ai-module.status-success {
  @extend %element-base-hover;
  cursor: pointer;
}

.ai-module-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.ai-module-detail-view {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}
