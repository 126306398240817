.text-segment {
  line-height: 1.1rem;
  white-space: pre-wrap;
}

.text-resume {
  background-color: var(--training-bg);
  padding: 0.5rem;
  margin: 0 -0.5rem;
}

.element-segment {
  & {
    background-color: var(--color-surface) !important;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .btn {
    margin-right: -0.5rem;
  }
}

.element-segment.list-group-flush {
  & {
    gap: 0 !important;
  }
  > .list-group-item {
    border-width: 0;
  }
}

.table-segments {
  td {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  tr.p-0 td {
    border-top: 0;
  }
}

.segment-meta {
  margin: 0.25rem;
  padding-left: 1.75rem;
}
