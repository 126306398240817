.element-help {
  @extend %element-base;
  & {
    --element-bg: var(--help-background);
    --element-shadow: var(--action-shadow);
    position: relative;
    overflow: hidden;
    z-index: 1;
    flex: 1;
    justify-content: space-between;
    align-self: stretch;
  }
  &:before {
    position: absolute;
    z-index: -1;
    width: 200px;
    height: 200px;
    left: -50%;
    top: -50%;
    opacity: 0.5;
    filter: blur(50px);
    content: "";
    background: var(--bright-color);
  }
  .icon {
    font-size: var(--icon-size-xl);
    color: rgba(black, .7);
  }
  .card-body {
    flex: none;
  }
}

.bright-default   { --bright-color: var(--color-neutral) !important; }
.bright-accent    { --bright-color: var(--color-accent) !important; }
.bright-success   { --bright-color: var(--color-success) !important; }
.bright-deeper    { --bright-color: var(--color-deeper) !important; }
.bright-on-demand { --bright-color: var(--color-ondemand) !important; }
