//
// GLOBAL
// - Styles
// - Shapes
//
// LAYOUT
// - Header
// - Navbar
// - Footer
// - Sidebar
//
// COMPONENTS
// - Modal
// - Card
// - Dropdown
//
// ELEMENTS
// - Avatars
// - Inputs
//

// GLOBAL

// Styles

$border-color: rgba($black, 0.1);

// Shapes

$border-radius-xs: 0.5rem; // 8 [CUSTOM]
$border-radius: 0.75rem; // 12
$border-radius-sm: 0.5rem; // 8
$border-radius-lg: 1rem; // 16
$border-radius-xlg: 1.5rem; // 24

//$border-radius:             1rem;    // 16
//$border-radius-xlg:         2.5rem;  // 40
//$border-radius-lg:          1.5rem;  // 24
//$border-radius-sm:          .75rem;  // 12
//$border-radius-xs:          .5rem;   // 8 [CUSTOM]

// LAYOUT

// Header

$header-light-bg: transparent;
$header-light-border: transparent;
$header-height: 3rem;

// Navbar

$navbar-padding-x: 0;
$navbar-padding-y: 0;

// Footer

$footer-height: auto;
$footer-light-bg: transparent;
$footer-light-borders: none;

// Sidebar

$sidebar-padding: 0.5rem;

$sidebar-dark-bg: $black;
$sidebar-dark-brand-bg: transparent;

$sidebar-dark-minimizer-bg: transparent;
$sidebar-dark-minimizer-hover-bg: rgba($white, 0.2);

// $sidebar-margin:                    16px;  // 1rem [CUSTOM]
// $sidebar-size:                      224px; // 14rem [CUSTOM]
// $sidebar-width:                     $sidebar-size + $sidebar-margin;

// $sidebar-brand-height:              56px;
// $sidebar-minimizer-height:          2.5rem;

// COMPONENTS

// Modals

$modal-header-padding-y: 2rem;
$modal-header-padding-x: 2rem;
$modal-inner-padding: 2rem;
// Dbería usar tamaños en variables
// pero Core UI hace calculos y falla

$modal-header-border-width: 0;
$modal-footer-border-width: 0;

//$modal-header-padding-y:            var(--grid-margin);
//$modal-header-padding-x:            var(--grid-margin);
//$modal-footer-margin-between:  .5rem !default;

// Cards

$card-border-width: 0;
$card-spacer-y: 1.25rem;
$card-spacer-x: 1.25rem;

// Breadcrumbs

$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-borders: none;

// Dropdowns

$dropdown-border-radius: $border-radius-sm;
$dropdown-border-width: 0;

// ELEMENTS

// Avatars
$avatar-width: 2.5rem;

// Inputs

$input-bg: rgba($black, 0.05);
$input-border-color: transparent;
$input-focus-bg: $white;

.btn-xs {
  height: 10.5px;
  width: 10.5px;
  padding: 0px;
  margin: 0px;
}

// CORE UI

@import "~@coreui/coreui/scss/coreui.scss";
