//
// LAYOUT
// - App
// - Header
// - Sidebar
// - Main
// - Footer
// - Content
// - Container
//

.c-app {
  & {
    background: var(--app-background);
    background-attachment: fixed;
  }
}

.c-header {
  & {
    backdrop-filter: var(--background-blur);
    padding-top: var(--header-spacing-top);
    padding-bottom: var(--header-spacing-bottom);
  }
}

.c-sidebar {
  // FOR NEW NAVIGATION
  //     margin: 1rem;
  //     width: $sidebar-size;
  //     border-radius: $border-radius-xlg;
  //     transform: translate3d(1rem,0,0);
  // }
  //.c-sidebar-nav {
  //    justify-content: center;
}

.c-main {
  padding-top: 0;
}

.c-footer {
  padding-top: var(--footer-spacing-top);
  padding-bottom: var(--footer-spacing-bottom);
}

#root {
  display: flex;
  flex-direction: column;
}
.c-content {
  @extend %flex-vertical;
}

.container-fluid {
  & {
    padding-right: var(--grid-margin) !important;
    padding-left: var(--grid-margin) !important;
  }
  .c-main & {
    @extend %flex-vertical;
  }
  .c-footer & {
    @extend %flex-horizontal;
  }
}

.pull-to-main {
  margin-right: var(--grid-margin-pull) !important;
  margin-left: var(--grid-margin-pull) !important;
}

.pull-to-wrapper {
  padding-top: 5rem;
  margin-top: -5rem;
  background: $white;
}

.page-title {
  position: fixed;
  top: 1.5rem;
  margin-left: 0rem;
  z-index: 20000;
  font-weight: bold;
  line-height: 1;
  margin: 0;
}

.sub-title {
  margin-bottom: 1rem;
}
