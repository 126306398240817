// Report grid

.report-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: min-content;
  grid-auto-flow: row dense;
}

.report-grid,
.report-tile {
  border: solid ($border-width/2) $border-color;
}

// Report tile

.report-tile {
  grid-column-end: span var(--tile-width);
  grid-row-end: span var(--tile-height);
  align-self: stretch;
}

.report-tile:empty {
  display: none;
}

.report-tile .card {
  min-height: 100%;
  margin-bottom: 0;
  border-radius: 0;
}
