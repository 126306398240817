.element-block {
  @extend %element-block;
  .card-body {
    padding: 2.25rem;
  }
}
a.element-block {
  @extend %element-block-hover;
}

.element-block-inside {
  & {
    @extend %element-block;
    display: block;
  }
  .card {
    background-color: transparent;
  }
}
a.element-block-inside {
  @extend %element-block-hover;
}

.element-item {
  & {
    border-radius: 5rem;
    line-height: 1rem;
  }
  .card-body {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.5rem;

    > svg {
      margin-left: 0.5rem;
    }
  }
}

.element-dropdown {
  @extend %element-dropdown;
  .card-body {
    padding: 1rem;
  }
}
a.element-dropdown {
  @extend %element-dropdown-hover;
}
