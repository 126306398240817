.canvas-legend {
  display: grid;
  // flex-direction: column;
  grid-template-columns: 1fr;
  width: 100%;
  // outline: 1px solid black;
  justify-content: center;
  // align-items: center;
  // height: 15%;
  // flex-shrink: 1;
}

.canvas-legend-items {
  display: grid;
  // flex-direction: row;
  grid-template-columns: 1fr 1fr 1fr;
  // justify-content: flex-start;
  // flex-wrap: wrap;
  width: 100%;
  // outline: 1px solid blue;
  // outline: 1px 0px solid grey;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.canvas-legend-items-column {
  display: flex;
  flex-direction: column;
  // justify-content: flex-start;
  // flex-wrap: wrap;
  // width: 100%;
  // outline: 1px solid blue;
}

.legend-item {
  flex: 1;
  display: flex;
  // flex-grow: 1;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
  // width: 50%;
  // justify-content: start;
  // outline: 1px solid red;
}

.legend-item-bordered {
  border: #ada4a4;
  // border-bottom: #ada4a4;
  border-style: solid dotted;
  border-width: 1px 1px;
}

.legend-item-icon-square {
  width: 24px;
  height: 24px;
  display: flex;
  font-weight: 600;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  color: white;
}
.legend-item-icon-circle {
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  border-radius: 100%;
}
.legend-item-label {
  flex: 1;
  // flex-grow: 1;
  padding: 0.8rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  // outline: 1px solid green;
}
.legend-item-value {
  text-align: right;
  font-weight: 600;
  padding: 0.8rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  // outline: 1px solid orange;
}

.legend-view-all-btn {
  text-align: center;
  margin: 0.3rem;
  text-decoration-line: underline;
  font-family: "Inter";
  font-size: 16px;
  cursor: pointer;
}
