.option-block {
  @extend %element-block;
  flex: 1;
  margin-bottom: 0;

  @extend %element-block-hover;
  cursor: pointer;
  &__selected {
    @extend %element-control;
    @extend %element-block-hover;
    flex: 1;
    margin-bottom: 0;
    outline: 2px solid var(--color-accent);
    cursor: pointer;
  }
}

.option-dropdown {
  @extend %element-dropdown;
  flex: 1;
  margin-bottom: 0;

  @extend %element-dropdown-hover;
  cursor: pointer;
  &__selected {
    @extend %element-control;
    @extend %element-dropdown-hover;
    flex: 1;
    margin-bottom: 0;
    outline: 2px solid var(--color-accent);
    cursor: pointer;
  }
}

.option-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
  min-height: 10rem;
}

.option-dropdown-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
  min-height: 1rem;
}

.option-item {
  @extend %element-control;
  display: flex;
  position: relative;
  z-index: 100;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid black;
  // margin-bottom: 1rem;
  cursor: pointer;
  &__selected {
    @extend %element-control;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    outline: 2px solid var(--color-accent);
    margin-bottom: 1rem;
    cursor: pointer;
  }
  &__selected_light {
    @extend %element-control;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    outline: 1px solid var(--color-accent);
    margin-bottom: 1rem;
    cursor: pointer;
  }
}

.option-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 0.5rem;
}

.option-list-flex {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.option-radio {
  outline: 1.3px solid rgba(0, 0, 0, 0.2);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  &__selected {
    outline: 1.3px solid var(--color-accent);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    color: var(--color-accent);
    &__tiny {
      width: 6px;
      height: 6px;
      border-radius: 100%;
      color: var(--color-accent);
      background-color: var(--color-accent);
    }
  }
}

.option-dropdown {
  @extend %element-dropdown;
  flex: 1;
  margin-bottom: 0;

  @extend %element-block-hover;
  cursor: pointer;
  &__selected {
    @extend %element-control;
    @extend %element-block-hover;
    flex: 1;
    margin-bottom: 0;
    outline: 2px solid var(--color-accent);
    cursor: pointer;
  }
}

.submit-large {
  @extend %element-button;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
  outline: 1px solid black;

  @extend %element-button-hover;
  cursor: pointer;
  &__selected {
    flex: 1;
    margin-bottom: 0;
    outline: 2px solid var(--color-accent);
    cursor: pointer;
  }
}
